import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import SocialProfile from '../../common/SocialProfile/SocialProfile';
import { IoLogoGithub, IoLogoTwitter, IoLogoInstagram, IoLogoLinkedin } from 'react-icons/io';
import { AboutWrapper, AboutImage, AboutPageTitle, AboutDetails, SocialProfiles } from './style';
import InstagramEmbed from 'react-instagram-embed';

const SocialLinks = [
	{
		icon: <IoLogoGithub />,
		url: 'https://www.github.com/xiumingxu/',
		tooltip: 'Github'
	},
	{
		icon: <IoLogoInstagram />,
		url: 'https://www.instagram.com/xixovo/',
		tooltip: 'Instagram'
	},
	// {
	// 	icon: <IoLogoTwitter />,
	// 	url: 'https://twitter.com/redqinc',
	// 	tooltip: 'Twitter'
	// },
	{
		icon: <IoLogoLinkedin />,
		url: 'https://www.linkedin.com/in/xiumingxu/',
		tooltip: 'Linked In'
	}
];

interface AboutProps {}

const About: React.FunctionComponent<AboutProps> = () => {
	const Data = useStaticQuery(graphql`
		query {
			avatar: file(absolutePath: { regex: "/about.jpg/" }) {
				childImageSharp {
					fluid(maxWidth: 1770, quality: 90) {
						...GatsbyImageSharpFluid
					}
				}
			}
			site {
				siteMetadata {
					author
					about
				}
			}
		}
	`);

	return (
		<AboutWrapper>
			<AboutPageTitle>
				<h2>ABOUT ME</h2>
				<p />
			</AboutPageTitle>

			<AboutImage>
				<Image fluid={Data.avatar.childImageSharp.fluid} alt='author' />
			</AboutImage>

			<AboutDetails>
				<h2>Hey there, I'm Xiuming XU </h2>
				<p>
					In the past few years, I worked as Creative Software Engineer in several domains. 
					It was interesting that I was deeply involved in digital and physical products and now big scale platforms.
					I am an explorer, not only a traveler around the world, but also in my mindset - thinking and reading and doing. 
					I am resilient to meet the unknown, ambiguity, and embracing constant challenges.
				</p>
				<p>
					I love to see the impact of my handcraft, and practically influence the huge number of customers and
					users. It is challenging, but very exciting in the end.
				</p>
				<p>
					My solid engineering background is the base for me to solve critical problems and I am honored to
					have participated in the whole life cycle of market-launched products in the E-Vehicle company, Financial ML teams. 
					My expertise is suited in a team where creators and technology experts
					dream up ideas together. My background in CS, HCI and entrepreneurship supports me in versatile role
					between business, users and technology, in order to craft a solution within the business
					requirements and market needs.
				</p>
				<p>I would love to hear new ideas and am ready to fix hands-on problems.</p>

				<p>- Xiuming</p>
				<div style={{"width": "100%"}}>
					<InstagramEmbed
						url='https://www.instagram.com/p/BsbcOkxliRn/'
						hideCaption={false}
						containerTagName='div'
						maxWidth={2000}
					
						// injectScript
						// onLoading={() => {}}
						// onSuccess={() => {}}
						// onAfterRender={() => {}}
						// onFailure={() => {}}
						
					/>
				</div>

				<SocialProfiles>
					<SocialProfile items={SocialLinks} />
				</SocialProfiles>
			</AboutDetails>
		</AboutWrapper>
	);
};

export default About;
